import http from "./http";

const createErrorResponse = (err) => {
  if (Object.keys(err).length === 0) {
    return {
      error: true,
      message: "Service is offline",
    };
  }

  return {
    error: true,
    message: err.data,
  };
};

export const getAppointmentsForDay = async (date) => {
  try {
    var result = await http.get("/appointment/getApptsByDate?date=" + date);
    return result.data;
  } catch (err) {
    return createErrorResponse(err);
  }
};

export const getLoggedInUser = async () => {
  try {
    var result = await http.get("/user/getUser");
    return result.data;
  } catch (err) {
    return createErrorResponse(err);
  }
};

export const createAccount = async (firstName, lastName, email, password) => {
  const data = {
    firstName,
    lastName,
    email,
    password,
  };
  try {
    var result = await http.post("/user/create", data);
    return result.data;
  } catch (err) {
    return createErrorResponse(err);
  }
};

export const resetAccount = async (email) => {
  const data = {
    emailAddress: email,
  };
  try {
    var result = await http.post("/user/recover", data);
    return result.data;
  } catch (err) {
    return createErrorResponse(err);
  }
};

export const resetPassword = async (email, password, token) => {
  const data = {
    emailAddress: email,
    resetKey: token,
    newPassword: password,
  };
  try {
    var result = await http.post("/user/reset", data);
    return result.data;
  } catch (err) {
    return createErrorResponse(err);
  }
};

export const getAppointmentsForMonth = async (month, year) => {
  try {
    var result = await http.get(
      "/appointment/getApptsByDate?month=" + month + "&year=" + year
    );
    return result.data;
  } catch (err) {
    return createErrorResponse(err);
  }
};

export const login = async (email, password) => {
  try {
    var result = await http.post("/user/login", {
      email,
      password,
    });
    return result.data;
  } catch (err) {
    return createErrorResponse(err);
  }
};

export const logout = async () => {
  try {
    var result = await http.get("/user/logout");
    return result.data;
  } catch (err) {
    return createErrorResponse(err);
  }
};

export const saveAppointment = async (appt) => {
  try {
    const result = await http.post("/appointment/SaveAppointment", appt);
    return result.data;
  } catch (err) {
    return createErrorResponse(err);
  }
};

export const deleteAppointment = async (appt) => {
  try {
    const result = await http.get("/appointment/Delete?id=" + appt.id);
    return result.data;
  } catch (err) {
    return createErrorResponse(err);
  }
};

export const saveContactRequest = async (data) => {
  const form = {
    name: "Contact Request Form",
    fields: [
      {
        name: "Name",
        value: data.name,
      },
      {
        name: "Phone",
        value: data.phone,
      },
      {
        name: "Email",
        value: data.email,
      },
      {
        name: "Message",
        value: data.message,
      },
    ],
  };
  try {
    const result = await http.post("/Forms/SaveEntry", form);
    return result.data;
  } catch (err) {
    return createErrorResponse(err);
  }
};

export const saveAppointmentRequest = async (data) => {
  const form = {
    name: "Appointment Request Form",
    fields: [
      {
        name: "Name",
        value: data.name,
      },
      {
        name: "Appointment Date",
        value: data.appointmentDate,
      },
      {
        name: "Phone",
        value: data.phone,
      },
      {
        name: "Email",
        value: data.email,
      },
      {
        name: "Message",
        value: data.message,
      },
    ],
  };
  try {
    const result = await http.post("/Forms/SaveEntry", form);
    return result.data;
  } catch (err) {
    return createErrorResponse(err);
  }
};
