import React, { useEffect, useState } from "react";
import "./Appbar.css";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { subscribe, unsubscribe } from "../../services/events";
import { getUser, signOut } from "../../services/userService";
import { SplitButton } from "primereact/splitbutton";

export const Appbar = (props) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [name, setName] = useState("");

  const navigate = useNavigate();
  const userItems = [
    {
      label: "Upload Ddocs",
      icon: "pi pi-upload",
      command: () => {
        navigate("/upload");
      },
    },
    {
      label: "Log out",
      icon: "pi pi-sign-out",
      command: () => {
        signOut();
        navigate("/");
      },
    },
  ];

  useEffect(() => {
    subscribe("loggedIn", () => {
      const user = getUser();
      setName(user.lastName + ", " + user.firstName);
      setLoggedIn(true);
    });

    subscribe("loggedOut", () => {
      setName("");
      setLoggedIn(false);
    });

    return () => {
      unsubscribe("loggedIn", () => setLoggedIn(false));
      unsubscribe("loggedOut", () => setLoggedIn(false));
    };
  }, []);

  return (
    <div className="bar bg-bluegray-50">
      <div className="companySection">
        <div
          className="text-2xl text-primary font-bold mb-3"
          onClick={() => navigate("/home")}
        >
          Christopher Newey CPA, llc
        </div>
      </div>
      <div className="loginSection">
        {!loggedIn && (
          <Button
            label="Login"
            type="button"
            className="mr-3 p-button-raised"
            onClick={() => navigate("login")}
          />
        )}
        {loggedIn && (
          <p
            className="text-md text-primary font-bold mb-1"
            style={{ marginRight: "10px" }}
          >
            <SplitButton
              icon="pi pi-user"
              label={name}
              model={userItems}
              className="mr-2 mb-2"
            ></SplitButton>
          </p>
        )}
      </div>
    </div>
  );
};
export default Appbar;
