import React from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

export const Home = () => {
  const navigate = useNavigate();

  return (
    <div className="grid grid-nogutter surface-0 text-800">
      <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
        <section>
          <div className="col-12 md:col-10 xl:col-8 p-6 text-center md:text-left flex align-items-center ">
            <img
              src="assets/images/logo2.png"
              alt="hero"
              className="md:ml-auto block md:h-full"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
              }}
            />
          </div>
          <div className="text-6xl text-primary font-bold mb-3">
            Services we offer
          </div>
          <div className="mt-0 mb-4 text-700 line-height-3">
            <ul>
              <li>Business Tax Prep</li>
              <li>Forensic Accounting</li>
              <li>Individual Tax Prep</li>
              <li>Bookkeeping</li>
              <li>Consulting</li>
              <li>Audit Reviews and Compilations</li>
              <li>Compliance</li>
            </ul>
          </div>

          <Button
            label="Contact Us"
            type="button"
            className="mr-3 p-button-raised"
            onClick={() => navigate("/contact")}
          />
          <Button
            label="Schedule an Appointment"
            type="button"
            className="p-button-outlined"
            onClick={() => navigate("/calendar")}
          />
        </section>
      </div>
      <div className="col-12 md:col-6 overflow-hidden">
        <img
          src="assets/images/carousel_3.jpg"
          alt="hero"
          className="md:ml-auto block md:h-full"
          style={{
            clipPath: "polygon(8% 0, 100% 0%, 100% 100%, 0 100%)",
            maxWidth: "100%",
            maxHeight: "100%",
          }}
        />
      </div>
    </div>
  );
};
export default Home;
