import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { resetAccount } from "../../services/api";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";

export const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const toast = useRef(null);
  const navigate = useNavigate();

  const doReset = async (email) => {
    if (email.length < 5) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "You must enter a valid email address",
      });
      return;
    }
    var result = await resetAccount(email);
    if (result.error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: result.message,
      });
      return;
    }

    alert("A reset link has been sent to you email account");

    navigate("/login");
  };

  return (
    <>
      <Toast ref={toast}></Toast>
      <div className="flex align-items-center justify-content-center">
        <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
          <div className="text-center mb-5">
            <div className="text-900 text-3xl font-medium mb-3">
              Reset Password
            </div>
          </div>

          <div>
            <label htmlFor="email" className="block text-900 font-medium mb-2">
              Email Address
            </label>
            <InputText
              id="email"
              type="text"
              placeholder="Email address"
              className="w-full mb-3"
              onChange={(e) => setEmail(e.target.value)}
            />

            <Button
              label="Reset Account"
              icon="pi pi-user"
              className="w-full"
              onClick={() => doReset(email)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default ForgotPassword;
