import axios from "axios";
import { signOut } from "./userService";

export function create(baseUrl) {
  const ax = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
  });

  ax.interceptors.response.use(
    (response) => {
      return response;
    },
    (err) => {
      if (err.response.status === 401) {
        return (window.location.href = process.env.PUBLIC_URL + "/login");
      } else {
        throw err.response;
      }
    }
  );

  return ax;
}
