import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { login } from "../../services/api";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import { signIn } from "../../services/userService";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const toast = useRef(null);
  const navigate = useNavigate();

  const doSignIn = async (email, password) => {
    const result = await login(email, password);
    if (result.error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `${result?.message}`,
      });
      return;
    }
    signIn(result);
    navigate("/");
  };
  return (
    <>
      <Toast ref={toast}></Toast>
      <div className="flex align-items-center justify-content-center">
        <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
          <div className="text-center mb-5">
            <div className="text-900 text-3xl font-medium mb-3">
              Welcome Back
            </div>
            <span className="text-600 font-medium line-height-3">
              Don't have an account?
            </span>
            <a
              className="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
              onClick={() => navigate("/create")}
            >
              Create one
            </a>
          </div>

          <div>
            <label htmlFor="email" className="block text-900 font-medium mb-2">
              Email
            </label>
            <InputText
              id="email"
              type="text"
              placeholder="Email address"
              className="w-full mb-3"
              onChange={(e) => setEmail(e.target.value)}
            />

            <label
              htmlFor="password"
              className="block text-900 font-medium mb-2"
            >
              Password
            </label>
            <InputText
              id="password"
              type="password"
              placeholder="Password"
              className="w-full mb-3"
              onChange={(e) => setPassword(e.target.value)}
            />

            <div className="flex align-items-center justify-content-between mb-6">
              <a
                className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
                onClick={() => navigate("/forgotpassword")}
              >
                Forgot your password?
              </a>
            </div>

            <Button
              label="Sign In"
              icon="pi pi-user"
              className="w-full"
              onClick={() => doSignIn(email, password)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
