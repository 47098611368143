import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { createAccount } from "../../services/api";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "../../services/utils";

export const CreateAccount = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const toast = useRef(null);
  const navigate = useNavigate();

  const validatePasswordComplexity = (password) => {
    if (password.length < 6) {
      return false;
    }

    return true;
  };

  const doCreateAccount = async (email, password, verifyPassword) => {
    if (firstName.length < 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please enter your first name",
      });
      return;
    }

    if (lastName.length < 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please enter your last name",
      });
      return;
    }

    if (password !== verifyPassword) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Passwords do not match",
      });
      return;
    }
    if (!validateEmail(email)) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Email address is not valid",
      });
      return;
    }

    if (!validatePasswordComplexity(password)) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Passowrds must be at least 6 characters",
      });
      return;
    }

    var result = await createAccount(firstName, lastName, email, password);
    if (result.error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: result.message,
      });
      return;
    }

    navigate("/login");
  };

  return (
    <>
      <Toast ref={toast}></Toast>
      <div className="flex align-items-center justify-content-center">
        <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
          <div className="text-center mb-5">
            <div className="text-900 text-3xl font-medium mb-3">
              Create Account
            </div>
          </div>

          <div>
            <label
              htmlFor="firstName"
              className="block text-900 font-medium mb-2"
            >
              First Name
            </label>
            <InputText
              id="firstName"
              type="text"
              placeholder="First Name"
              className="w-full mb-3"
              onChange={(e) => setFirstName(e.target.value)}
            />

            <label
              htmlFor="lastName"
              className="block text-900 font-medium mb-2"
            >
              Last Name
            </label>
            <InputText
              id="lastName"
              type="text"
              placeholder="Last Name"
              className="w-full mb-3"
              onChange={(e) => setLastName(e.target.value)}
            />

            <label htmlFor="email" className="block text-900 font-medium mb-2">
              Email Address
            </label>
            <InputText
              id="email"
              type="text"
              placeholder="Email address"
              className="w-full mb-3"
              onChange={(e) => setEmail(e.target.value)}
            />

            <label
              htmlFor="password"
              className="block text-900 font-medium mb-2"
            >
              Password
            </label>
            <InputText
              id="password"
              type="password"
              placeholder="Password"
              className="w-full mb-3"
              onChange={(e) => setPassword(e.target.value)}
            />

            <label
              htmlFor="password"
              className="block text-900 font-medium mb-2"
            >
              Re-enter Password
            </label>
            <InputText
              id="verifyPassword"
              type="password"
              placeholder="Password"
              className="w-full mb-3"
              onChange={(e) => setVerifyPassword(e.target.value)}
            />

            <Button
              label="Create Account"
              icon="pi pi-user"
              className="w-full"
              onClick={() => doCreateAccount(email, password, verifyPassword)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateAccount;
