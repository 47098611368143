import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import ScheduleForm from "./Components/ScheduleForm";
import Appbar from "./Components/Appbar";
import ContactForm from "./Components/ContactForm";
import Login from "./Components/Login";
import React, { useEffect } from "react";
import { getUser, signOut } from "./services/userService";
import { getLoggedInUser } from "./services/api";
import { signIn } from "./services/userService";
import Upload from "./Components/Upload";
import CreateAccount from "./Components/CreateAccount";
import ForgotPassword from "./Components/ForgotPassword/ForgotPassword";
import ResetPassword from "./Components/ResetPassword/ResetPassword";

function App() {
  useEffect(() => {
    async function checkUser() {
      const loggedIn = await getLoggedInUser();
      if (!loggedIn) {
        signOut();
      }
    }
    const user = getUser();
    if (user) {
      console.log("checking user");
      checkUser();
      signIn(user);
    }
  }, []);
  return (
    <div className="App">
      <div>
        <div>
          <Appbar />
        </div>
        <div className="appMain">
          <Routes>
            <Route>
              <Route path="/" element={<Home />}></Route>
              <Route path="/home" element={<Home />}></Route>
              <Route path="/calendar" element={<ScheduleForm />}></Route>
              <Route path="/contact" element={<ContactForm />}></Route>
              <Route path="/login" element={<Login />}></Route>
              <Route path="/upload" element={<Upload />}></Route>
              <Route path="/create" element={<CreateAccount />}></Route>
              <Route
                path="/forgotpassword"
                element={<ForgotPassword />}
              ></Route>
              <Route path="/resetpassword" element={<ResetPassword />}></Route>
            </Route>
          </Routes>
        </div>

        <div className="copyright">
          <hr />
          Copyright © 2022 | Christopher Newey CPA & Associates, LLC
        </div>
      </div>
    </div>
  );
}

export default App;
