import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { saveAppointmentRequest } from "../../services/api";
import { Toast } from "primereact/toast";
import { Navigate, useNavigate } from "react-router-dom";
import { validateEmail } from "../../services/utils";
import { Calendar } from "primereact/calendar";

export const ScheduleForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const toast = useRef(null);
  const navigate = useNavigate();

  const submit = async () => {
    if (name.length < 5) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please enter your name",
      });
      return;
    }
    if (!validateEmail(email)) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please enter a valid email address",
      });
      return;
    }
    if (!appointmentDate) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please enter an appointment date",
      });
      return;
    }
    if (message.length < 5) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail:
          "Please enter a short message about the services you're interested in.",
      });
      return;
    }

    var result = await saveAppointmentRequest({
      name,
      appointmentDate,
      phone,
      email,
      message,
    });
    if (result.error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: result.message,
      });
      return;
    }

    setSubmitted(true);
  };

  return (
    <>
      <Toast ref={toast}></Toast>
      <div className="flex align-items-center justify-content-center">
        <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
          <div className="text-center mb-5">
            <div className="text-900 text-3xl font-medium mb-3">
              Schedule an Appointment
            </div>
          </div>
          {submitted && (
            <div>
              <h3>Your request has been sent. Thank you.</h3>
              <div>
                <Button
                  label="Ok"
                  icon="pi pi-check"
                  className="w-full"
                  onClick={() => navigate("/")}
                />
              </div>
            </div>
          )}
          {!submitted && (
            <div>
              <label htmlFor="name" className="block text-900 font-medium mb-2">
                Name
              </label>
              <InputText
                id="name"
                type="text"
                placeholder="Name"
                className="w-full mb-3"
                onChange={(e) => setName(e.target.value)}
              />
              <label htmlFor="date" className="block text-900 font-medium mb-2">
                Date
              </label>
              <span className="p-float-label">
                <Calendar
                  inputId="appointment_date"
                  style={{ width: "100%" }}
                  showTime
                  hourFormat="12"
                  value={appointmentDate}
                  onChange={(e) => setAppointmentDate(e.value)}
                  showIcon
                />
                <label htmlFor="appointment_date">Appointment Date</label>
              </span>

              <label
                htmlFor="phone"
                className="block text-900 font-medium mb-2"
              >
                Phone Number
              </label>
              <InputText
                id="phone"
                type="text"
                placeholder="Phone"
                className="w-full mb-3"
                onChange={(e) => setPhone(e.target.value)}
              />

              <label
                htmlFor="email"
                className="block text-900 font-medium mb-2"
              >
                Email Address
              </label>
              <InputText
                id="email"
                type="text"
                placeholder="Email address"
                className="w-full mb-3"
                onChange={(e) => setEmail(e.target.value)}
              />

              <label
                htmlFor="message"
                className="block text-900 font-medium mb-2"
              >
                Message
              </label>
              <InputTextarea
                id="message"
                type="text"
                rows="6"
                placeholder="Message"
                className="w-full mb-3"
                onChange={(e) => setMessage(e.target.value)}
              />

              <Button
                label="Submit"
                icon="pi pi-upload"
                className="w-full"
                onClick={() => submit()}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ScheduleForm;
