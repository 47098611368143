import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { resetPassword } from "../../services/api";
import { Toast } from "primereact/toast";
import { useNavigate, useSearchParams } from "react-router-dom";

export const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const toast = useRef(null);

  const token = searchParams.get("token");
  if (!token) {
    navigate("/");
  }

  const validateEmail = (email) => {
    var reg =
      "^[a-zA-Z0-9]+(.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(.[a-zA-Z0-9-]+)*(.[a-zA-Z]{2,15})$";
    if (!email.match(reg)) {
      return false;
    }
    return true;
  };

  const validatePasswordComplexity = (password) => {
    if (password.length < 6) {
      return false;
    }

    return true;
  };

  const doReset = async (email, password, verifyPassword) => {
    if (password !== verifyPassword) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Passwords do not match",
      });
      return;
    }
    if (!validateEmail(email)) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Email address is not valid",
      });
      return;
    }

    if (!validatePasswordComplexity(password)) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Passowrds must be at least 6 characters",
      });
      return;
    }

    var result = await resetPassword(email, password, token);
    if (result.error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: result.message,
      });
      return;
    }
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Your password has been changed",
    });
    navigate("/login");
  };

  return (
    <>
      <Toast ref={toast}></Toast>
      <div className="flex align-items-center justify-content-center">
        <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
          <div className="text-center mb-5">
            <div className="text-900 text-3xl font-medium mb-3">
              Reset Password
            </div>
          </div>

          <div>
            <label htmlFor="email" className="block text-900 font-medium mb-2">
              Email Address
            </label>
            <InputText
              id="email"
              type="text"
              placeholder="Email address"
              className="w-full mb-3"
              onChange={(e) => setEmail(e.target.value)}
            />

            <label
              htmlFor="password"
              className="block text-900 font-medium mb-2"
            >
              Password
            </label>
            <InputText
              id="password"
              type="password"
              placeholder="Password"
              className="w-full mb-3"
              onChange={(e) => setPassword(e.target.value)}
            />

            <label
              htmlFor="password"
              className="block text-900 font-medium mb-2"
            >
              Re-enter Password
            </label>
            <InputText
              id="verifyPassword"
              type="password"
              placeholder="Password"
              className="w-full mb-3"
              onChange={(e) => setVerifyPassword(e.target.value)}
            />

            <Button
              label="Reset Account"
              icon="pi pi-user"
              className="w-full"
              onClick={() => doReset(email, password, verifyPassword)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default ResetPassword;
