import { logout } from "./api";
import { publish } from "./events";

export const signIn = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
  publish("loggedIn");
};

export const getUser = () => {
  var user = localStorage.getItem("user");
  if (user) {
    return JSON.parse(user);
  }
  return null;
};

export const signOut = async () => {
  await logout();
  localStorage.removeItem("user");
  publish("loggedOut");
};
