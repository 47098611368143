import config from "./config";
import { create } from "./axiosService";

const http = {
  get: async (url) => {
    const axios = create(config.api_base_url);
    return await axios.get(url);
  },
  post: async (url, data) => {
    const axios = create(config.api_base_url);
    return await axios.post(url, data);
  },
};

export default http;
